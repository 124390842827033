import 'vue-select/dist/vue-select.css';
import axios from "axios"
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { directive as VNumber } from '@coders-tm/vue-number-format'
import { ref } from 'vue';
import {useToast} from 'vue-toast-notification';
import vSelect from 'vue-select'

import 'vue-toast-notification/dist/theme-bootstrap.css';

const $toast = useToast();
const backend_data=window.backend_data

export const company_show_app={
    data() {
        return {
            program:{
                intereses_estandares:[],
                certificacion_actual:null,
                pais_interes:[],
            },
            prueba:4,
            buscadores:{municipios:{data:{results:[]}},ciiu:{data:{results:[]}},paises:{data:{results:[]}}},
            currentprogram:{careextension_productoservicio:"Prueba",careextension_paises_interes:[],internacionalizacion20_ciiu_objetivo:[],current_standard:null,other_standars:[],careextension_intencion_exportar:true,monteria_intencion_exportar:true},
            enroll_program:null,
            formas_adquisicion_list:backend_data.formas_adquisicion,
            encuentra_certificada:{si:"Si",no:"No",enproceso:"En proceso"},
            sino:{si:"Si",no:"No"},
            form_error:[],
            strategies:[],
            files:{},
            filemeta:{filename:null,tipo:null,descripcion:null},
            spinners:{sendfile:false},
            nuevapersona:"",
            personas: [],
        }   
    },
    methods: {
        setcurrentprogram:function(program_id) {
            axios.get("/programs/"+program_id+".json").then(res=>{
                axios.get("/strategies.json?program="+res.data.type).then(res2=>{
                    console.log("resd2:",res2.data);
                    this.strategies=res2.data
                    console.log("resdata:",res.data);
                    this.currentprogram=res.data;
                    console.log("resdata2:",this.currentprogram);
                    $(`#modal_${res.data.type}_${res.data.id}`).modal("show")
                })
            })
        },  
        enroll:function(){
            axios.patch("/companies/"+backend_data.company_id+"/add_program",{authenticity_token:backend_data.authenticity_token,program:this.enroll_program}).then(res=>{
                location.reload();
            }).catch(err=>{
                console.log("ERROR",err);
                $toast.error("Error: "+err.response.data.msg,{position:"top-right",duration:5000});
            })
        },
        buscadorpaises:function(search, loading) {
            loading(true);
            axios.get(`/findcountry/?term=${search}`).then(res=>{
              this.buscadores.paises=res.data;
              loading(false)
            });
        },
        buscadorciiu: function (search, loading) {
            loading(true);
            axios.get(`/findciiu/?term=${search}`).then(res => {
                this.buscadores.ciiu = res;
                loading(false)
            });
        },
        update_form:function() {
            this.form_error=[]
            axios.patch("/programs/"+this.currentprogram.id+".json",{authenticity_token:backend_data.authenticity_token,program:this.currentprogram}).then(res=>{
                $toast.open({type:"success",message:"Programa Actualizado",position:"top-right",duration:5000})
                $(`#modal_${this.currentprogram.type}_${this.currentprogram.id}`).modal("hide")
                this.set_flag_program('sent_first_form', true)
                location.reload()
            }).catch(err=>{
                for (let key in err.response.data) {
                    this.form_error.push(key)
                    for (let e of err.response.data[key]){
                        $toast.error("Error: "+e,{position:"top-right",duration:5000})
                    }
                }
            })
        },
        set_flag_program: function(flag, value){
            let flags = {}
            flags[flag] = value
            axios.patch("/programs/"+this.currentprogram.id+"/set_flags",{flags:flags,authenticity_token:backend_data.authenticity_token})
            .then(res => {
                console.log('la respuesta es ', res);
            }).catch(error => {
                console.log('the error is ', error);
            })
        },
        openform:function(form){
            $(`#modal_${form}`).modal("show")
        },
        fileupload: function(event, type,program_id) {
            let name = "doc_" + type;
            this.files[name] = event.target.files[0];
            console.log('Selected file:', this.files[name]);
            this.filesend(program_id, type, this.files[name].name);
        },
        send_docs:function(program_id){
            axios.patch("/programs/"+program_id+".json",{authenticity_token:backend_data.authenticity_token,program:{flags:{send_docs:true}}}).then(res=>{
                $toast.success("Enviado!",{position:"top-right",duration:5000})
            }).catch(err=>{
                $toast.error("Error: "+e,{position:"top-right",duration:5000})
            })
        },
        delete_file:function(id){
            axios.delete("/docs/"+id+".json",{data:{authenticity_token:backend_data.authenticity_token}}).then(res=>{
                location.reload();
            })
        },

        filesend: function(program_id, type, filename) {
            this.spinners.sendfile = true;
            let formData = new FormData();
            formData.append('file', this.files["doc_" + type]);
            formData.append('type', type);
            formData.append('description', "");
            formData.append('user_id', backend_data.user_id);
            formData.append('company_id', backend_data.company_id);
            formData.append('program_id', program_id);
            formData.append('filename', filename);
            formData.append('bucket', 'normania-docs');
            formData.append('authenticity_token', backend_data.authenticity_token);
        
            axios.post('/docs', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(res => {
                    console.log('rta',res);
                    this.spinners.sendfile = false;
                    location.reload();
                })
                .catch(err => {
                    this.spinners.sendfile = false;
                    alert('error subiendo archivo', err);
                });
        }
        
    },
    components:{
        'v-select': vSelect,
        VueNumber
    }
}